import React from "react"
import { useCalendlyEventListener, InlineWidget } from "react-calendly"

import Container from "./Container"
import { formatNextAvailableDate } from "./NextAvailable"
import { useNextAvailable } from "./PractitionerLandingPage"

const BookDemoSection = () => {
  const [iframeHeight, setIframeHeight] = React.useState(750)
  const { nextAvailableTime } = useNextAvailable()
  const readableTime = nextAvailableTime?.startTime && formatNextAvailableDate(nextAvailableTime.startTime)

  useCalendlyEventListener({
    onPageHeightResize: (e) => {
      setIframeHeight(e.data.payload.height)
    }
  })

  return (
    <div id="book-demo" className="py-8 pb-36 lg:pb-16">
      <Container>
        <div className="text-center">
          <div className="font-serif text-[32px] font-extrabold leading-[48px] lg:text-[22px] lg:leading-[32px]">
            Schedule a Demo
          </div>
          {readableTime && (
            <div className="text-2xl leading-9 lg:text-base lg:leading-normal">Next available {readableTime}</div>
          )}
        </div>
        <div className="mx-auto w-[1000px] lg:mt-10 lg:w-full" style={{ height: iframeHeight }}>
          <InlineWidget url="https://calendly.com/healdotmepro/meet-heal-me-clone" styles={{ height: iframeHeight }} />
        </div>
      </Container>
    </div>
  )
}

export default BookDemoSection
