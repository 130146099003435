import React from "react"

import Container from "./Container"
import CopySection from "./CopySection"

const NewPhotoshootSection = () => (
  <div className="relative lg:py-8">
    {/* gradient */}
    <div className="absolute top-0 aspect-square w-1/2 rounded-full bg-teal opacity-40 blur-[500px] lg:left-unset lg:right-0 lg:blur-[100px] min_lg:left-[536px]" />
    <div className="absolute left-0 top-[92px] aspect-square w-1/2 rounded-full bg-orange opacity-40 blur-[500px] lg:blur-[100px]" />

    <Container className="h-[440px] lg:h-[480px]">
      <CopySection title="Elevate your website with a professional holistic practice photoshoot.">
        <ul className="space-y-2">
          <li className="flex items-center gap-2">
            <span>📸</span>
            <span>
              <span className="font-bold">1 hr professional photoshoot</span> included when you join
            </span>
          </li>
          <li className="flex items-center gap-2">
            <span>🌎</span>
            <span>
              <span className="font-bold">Local photographers</span> across all 50 states and 10 provinces
            </span>
          </li>
          <li className="flex items-center gap-2">
            <span>🙏</span>
            <span>
              <span className="font-bold">Build more trust</span> with professional, high resolution images
            </span>
          </li>
        </ul>
      </CopySection>
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/ps-1-1.jpg"
        alt="Photoshoot headshot"
        className="absolute left-[761px] top-[42px] w-[234px] rounded-lg shadow-landingPage lg:left-[40px] lg:top-[300px] lg:w-[114px] lg:shadow-landingPageMobile"
      />
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/ps-1-2.jpg"
        alt="Photoshoot headshot"
        className="absolute left-[1036px] top-[54px] w-[138px] rounded-lg shadow-landingPage lg:left-[220px] lg:top-[320px] lg:shadow-landingPageMobile"
      />
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/ps-1-3.jpg"
        alt="Photoshoot headshot"
        className="absolute left-[933px] top-[217px] w-[261px] rounded-lg shadow-landingPage lg:left-[62px] lg:top-[435px] lg:w-[120px] lg:shadow-landingPageMobile"
      />
    </Container>
  </div>
)

export default NewPhotoshootSection
