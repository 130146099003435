import React, { useEffect, useState } from "react"

const TrustpilotWidget = () => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)

    const script = document.createElement("script")
    script.src = "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  if (!isClient) {
    return (
      <div className="h-60 bg-transparent">
        <div className="mx-[50px] flex gap-8">
          {Array.from({ length: 4 }).map((_, index) => (
            <div className="h-[160px] flex-1 animate-pulse rounded bg-gray-light" key={index}></div>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="h-60">
      <div
        className="trustpilot-widget"
        data-locale="en-US"
        data-template-id="54ad5defc6454f065c28af8b"
        data-businessunit-id="61c4b68ac783ed407265cd10"
        data-style-height="240px"
        data-style-width="100%"
        data-theme="light"
        data-stars="5"
        data-review-languages="en">
        <a href="https://www.trustpilot.com/review/heal.me" target="_blank" rel="noopener noreferrer">
          Trustpilot
        </a>
      </div>
    </div>
  )
}

export default TrustpilotWidget
