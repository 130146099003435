import React from "react"
import { twMerge } from "tailwind-merge"

export const Button = ({ className, href = "/book_demo", text = "Schedule a Demo Today!" }) => (
  <a
    href={href}
    className={twMerge(
      "flex h-12 w-fit items-center justify-center rounded bg-black px-6 text-center text-base font-bold text-white shadow hover:shadow-landingPageButtonHover active:shadow-landingPageButtonPressed lg:h-10 lg:px-4",
      className
    )}>
    {text}
  </a>
)

export const LargeButton = ({ className, text, href }) => (
  <Button
    className={twMerge(
      "h-16 bg-teal px-16 py-2 text-xl hover:shadow-landingPageTealButtonHover active:shadow-landingPageTealButtonPressed lg:h-12",
      className
    )}
    text={text}
    href={href}
  />
)

export const OutlineButton = ({ className, text = "Get Started Now!", ...rest }) => (
  <LargeButton
    className={twMerge(
      "border border-teal bg-white text-teal hover:border-2 hover:shadow-none active:border-[3px] active:shadow-none",
      className
    )}
    text={text}
    {...rest}
  />
)
