import { CheckIcon } from "@heroicons/react/24/outline"
import React from "react"
import { twMerge } from "tailwind-merge"

import Container from "./Container"

const Button = ({ className, text, ...rest }) => (
  <a
    className={twMerge(
      "flex h-12 w-full items-center justify-center rounded bg-teal px-6 text-center font-bold text-white shadow hover:shadow-landingPageButtonHover active:shadow-landingPageButtonPressed lg:h-10 lg:px-4",
      className
    )}
    {...rest}>
    {text}
  </a>
)

export const OutlineButton = ({ className, text, ...rest }) => (
  <Button
    className={twMerge(
      "border border-teal bg-white text-teal hover:border-2 hover:shadow-none active:border-[3px] active:shadow-none",
      className
    )}
    text={text}
    {...rest}
  />
)

const NewPricingSection = () => {
  const variation = 1
  const customPayment = 999
  const monthlyPayment = variation === 1 ? 79 : 99

  return (
    <div className="py-10 lg:py-8">
      <Container>
        <div className="text-center">
          <div className="font-serif text-[32px] font-extrabold leading-[48px] lg:text-[22px] lg:leading-[32px]">
            Choose Your Membership
          </div>
          <div className="text-2xl leading-9 lg:text-base lg:leading-normal">
            Accepting licensed and certified practitioners in the US & Canada
          </div>
        </div>
        <div className="mt-16 flex justify-center gap-24 lg:mt-8 lg:flex-col lg:items-center lg:gap-8">
          <div className="w-[388px] gap-6 rounded-lg bg-white shadow-landingPage lg:mx-5 lg:w-full lg:border lg:border-gray-light">
            <div className="rounded-t-lg border-b border-gray-light bg-[#f5fafc] p-6">
              <div className="font-serif text-2xl font-extrabold">Professional Membership</div>
              <div className="font-bold">Personalized website</div>
            </div>

            <div className="p-6">
              <div className="flex flex-col justify-center rounded-lg bg-green-light p-4">
                <div className={variation === 1 ? "" : "text-center"}>
                  {variation === 1 && <div>1 payment of</div>}
                  {variation === 1 ? (
                    <>
                      <span className="font-serif text-[40px] font-extrabold leading-[56px]"> $588</span>
                      <span className="ml-2 text-[22px] leading-loose">then</span>
                    </>
                  ) : (
                    <span className="text-[22px] leading-loose">Just</span>
                  )}
                  <span className="font-serif text-[40px] font-extrabold leading-[56px]"> ${monthlyPayment}</span>
                  <span className="text-[22px] leading-loose">/mo</span>
                </div>
              </div>
              {variation === 2 && <div className="h-6" />}

              <div className="my-6 ml-4 flex flex-col gap-4">
                {variation === 1 && (
                  <div className="flex gap-3">
                    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-light">
                      <CheckIcon className="h-5 w-5 text-green" />
                    </div>
                    <div>Includes professional photoshoot</div>
                  </div>
                )}
                <div className="flex gap-3">
                  <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-light">
                    <CheckIcon className="h-5 w-5 text-green" />
                  </div>
                  <div>Website and domain hosting</div>
                </div>
                <div className="flex gap-3">
                  <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-light">
                    <CheckIcon className="h-5 w-5 text-green" />
                  </div>
                  <div>Integrated booking & payments</div>
                </div>
                <div className="flex gap-3">
                  <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-light">
                    <CheckIcon className="h-5 w-5 text-green" />
                  </div>
                  <div>Promotion on Heal.me</div>
                </div>
              </div>

              <div className="flex flex-col gap-6">
                <OutlineButton
                  text="Sign up Now"
                  href={
                    variation === 1
                      ? "https://buy.stripe.com/dR67vO3974u08hi29J"
                      : "https://buy.stripe.com/eVa8zSaBz2lS0OQdSB"
                  }
                />
                <Button text="Schedule a Demo" href="#book-demo" />
              </div>
            </div>
          </div>

          <div className="w-[388px] gap-6 rounded-lg bg-white shadow-landingPage lg:mx-5 lg:w-full lg:border lg:border-gray-light">
            <div className="rounded-t-lg border-b border-gray-light bg-[#f5fafc] p-6">
              <div className="font-serif text-2xl font-extrabold">Custom Membership</div>
              <div className="font-bold">Fully custom-built website</div>
            </div>

            <div className="p-6">
              <div className="flex flex-col justify-center rounded-lg bg-[#d5faf1] p-4">
                <div>2 payments of</div>
                <div>
                  <span className="font-serif text-[40px] font-extrabold leading-[56px]"> ${customPayment}</span>
                  <span className="ml-2 text-[22px] leading-loose">then</span>
                  <span className="font-serif text-[40px] font-extrabold leading-[56px]"> ${monthlyPayment}</span>
                  <span className="text-[22px] leading-loose">/mo</span>
                </div>
              </div>

              <div className="my-6 ml-4 flex flex-col gap-4">
                <div className="flex gap-3">
                  <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-light">
                    <CheckIcon className="h-5 w-5 text-green" />
                  </div>
                  <div>Everything in Professional</div>
                </div>
                {variation === 1 && (
                  <div className="flex gap-3">
                    <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-light">
                      <CheckIcon className="h-5 w-5 text-green" />
                    </div>
                    <div>Professional photoshoot</div>
                  </div>
                )}
                <div className="flex gap-3">
                  <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-light">
                    <CheckIcon className="h-5 w-5 text-green" />
                  </div>
                  <div>Dedicated account manager</div>
                </div>
                <div className="flex gap-3">
                  <div className="flex h-6 w-6 items-center justify-center rounded-full bg-green-light">
                    <CheckIcon className="h-5 w-5 text-green" />
                  </div>
                  <div>Custom web design & development</div>
                </div>
              </div>

              <div className="flex flex-col gap-6">
                {variation === 1 ? (
                  <OutlineButton text="Sign up Now" href="https://go.heal.me/professional-membership-white-glove" />
                ) : (
                  <Button text="Sign up Now" href="https://buy.stripe.com/7sI9DWdNL2lScxy8yi" />
                )}
                {variation === 1 ? (
                  <Button text="Schedule a Demo" href="#book-demo" />
                ) : (
                  <OutlineButton text="Schedule a Demo" href="#book-demo" />
                )}
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default NewPricingSection
