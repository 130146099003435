import { useState, useEffect } from "react"

// Use this if you want to ensure that the code only runs on the client side.
// Useful for preventing errors when rendering on the server.
const useIsClient = () => {
  const [isClient, setIsClient] = useState(false)

  useEffect(() => {
    setIsClient(true)
  }, [])

  return isClient
}

export default useIsClient
