import React from "react"
import { twMerge } from "tailwind-merge"

const CopySection = ({ title, subtitle, className, children }) => (
  <div
    className={twMerge(
      "inline-flex h-[420px] w-[632px] flex-col items-start justify-center gap-4 lg:h-fit lg:w-full",
      className
    )}>
    <div className="self-stretch font-serif text-[32px] font-extrabold leading-[48px] lg:text-[22px] lg:leading-[32px]">
      {title}
    </div>
    {subtitle && <div className="self-stretch text-[22px] leading-[32px] lg:text-lg">{subtitle}</div>}
    {children}
  </div>
)

export default CopySection
