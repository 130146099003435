import { ArrowUpIcon } from "@heroicons/react/24/outline"
import React from "react"

import { LargeButton } from "./Buttons"
import Container from "./Container"
import NextAvailable from "./NextAvailable"

const BottomCTA = () => (
  <div className="relative h-[1044px] overflow-hidden bg-cover bg-center pb-10 pt-20 lg:h-[1241px]">
    <Container>
      <div className="text-center">
        <div className="mb-10 lg:mb-4">
          <div className="mb-4 font-serif text-[56px] font-extrabold leading-[72px] lg:text-center lg:text-[40px] lg:leading-[48px] md:px-10">
            <span>Your</span> <span className="text-teal">all-in-one</span> <span>growth platform</span>
          </div>
          <div className="text-2xl leading-9 lg:text-center lg:text-lg lg:leading-7">
            Join Heal.me and level-up your holistic practice today
          </div>
        </div>
        <div className="mb-4 mt-[56px] flex justify-center lg:mt-10">
          <LargeButton className="lg:w-full" />
        </div>
        <NextAvailable className="lg:hidden" />
        <img
          loading="lazy"
          src="/images/practitioner-landing/graphics/arrows-up.svg"
          alt="Up arrow"
          className="mx-auto mt-8 h-[166px] w-[314px] translate-x-2 text-center lg:mt-4"
        />
      </div>
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/payments-large.png"
        alt="Payments"
        className="absolute left-[-100px] top-[172px] h-[233px] rounded-lg shadow-landingPage lg:hidden lg:shadow-landingPageMobile"
      />
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/rate-session.png"
        alt="New rating notification"
        className="absolute right-[-113px] top-[172px] w-[240px] rounded-lg shadow-landingPage lg:left-[13px] lg:right-unset lg:top-[758px] lg:w-[160px] lg:shadow-landingPageMobile"
      />
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/website-desktop-long.png"
        alt="Desktop website"
        className="absolute left-0 top-[475px] w-[211px] rounded-lg shadow-landingPage lg:left-unset lg:right-[-16px] lg:top-[950px] lg:w-[91px] lg:shadow-landingPageMobile"
      />
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/notification/new-booking-large.png"
        alt="New booking notification"
        className="absolute left-[-64px] top-[560px] z-10 w-[360px] rounded-lg bg-white shadow-landingPage lg:hidden lg:shadow-landingPageMobile"
      />
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/ps-5-1.jpg"
        alt="Virtual practitioner"
        className="absolute left-[392px] top-[411px] w-[404px] rounded-lg shadow-landingPage lg:static lg:mt-[-44px] lg:w-full lg:shadow-landingPageMobile"
      />
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/marketplace.png"
        alt="Marketplace"
        className="absolute right-[-106px] top-[408px] w-[325px] rounded-lg shadow-landingPage lg:hidden lg:shadow-landingPageMobile"
      />
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/calendar.png"
        alt="Calendar"
        className="absolute right-[80px] top-[703px] w-[240px] rounded-lg shadow-landingPage lg:right-[12px] lg:top-[733px] lg:w-[122px] lg:shadow-landingPageMobile"
      />
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/website-mobile-long.png"
        alt="Mobile website"
        className="absolute right-[-75px] top-[697px] w-[52px] rounded-lg shadow-landingPage lg:left-[30px] lg:right-unset lg:top-[922px] lg:w-[38px] lg:shadow-landingPageMobile"
      />
    </Container>
    <div className="absolute bottom-[150px] flex w-full justify-center text-gray-dark">
      <button
        className="flex flex-col items-center gap-4"
        onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          })
        }}>
        <ArrowUpIcon className="h-6 w-6" />
        <div>Go to top</div>
      </button>
    </div>
    <div className="absolute bottom-10 flex w-full justify-center text-gray-dark">© 2024 Heal.me Inc.</div>
  </div>
)

export default BottomCTA
