import React from "react"
import { twMerge } from "tailwind-merge"

import AnimatedSection from "../ProProfileShared/AnimatedSection"

const Container = ({ className, animate = true, children, ...rest }) => {
  if (!animate)
    return (
      <div className={twMerge("relative mx-auto max-w-[1216px] px-8 lg:max-w-[420px] lg:px-5", className)} {...rest}>
        {children}
      </div>
    )
  else
    return (
      <AnimatedSection
        className={twMerge("relative mx-auto max-w-[1216px] px-8 lg:max-w-[420px] lg:px-5", className)}
        {...rest}>
        {children}
      </AnimatedSection>
    )
}

export default Container
