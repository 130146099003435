import React from "react"

import Container from "./Container"
import CopySection from "./CopySection"

const MarketplaceSection = () => (
  <div className="py-16 lg:py-8">
    <Container className="flex flex-row-reverse items-center justify-between lg:flex-col-reverse lg:items-center lg:gap-8">
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/new-marketplace.png"
        alt="Marketplace"
        className="absolute left-[-40px] top-[40px] w-[613px] rounded-lg shadow-landingPage lg:static lg:mx-auto lg:mt-10 lg:w-[320px] lg:shadow-landingPageMobile"
      />
      <CopySection className="w-[500px]" title="Get found on the largest marketplace of holistic practitioners.">
        <ul className="space-y-2">
          <li className="flex items-center gap-2">
            <span>📢</span>
            <span>
              <span className="font-bold">500,000+ annual visitors</span> and growing
            </span>
          </li>
          <li className="flex items-center gap-2">
            <span>🔎</span>
            <span>
              <span className="font-bold">Clients search</span> by therapy & health issue
            </span>
          </li>
          <li className="flex items-center gap-2">
            <span>⭐</span>
            <span>
              <span className="font-bold">Verified client reviews</span> to amplify word-of-mouth
            </span>
          </li>
        </ul>
      </CopySection>
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/notification/new-rating-large.png"
        alt="New rating notification"
        className="absolute left-[-72px] top-[-40px] w-[336px] rounded-lg shadow-landingPage lg:static lg:hidden lg:w-full lg:shadow-landingPageMobile"
      />
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/customer-review.png"
        alt="Customer review"
        className="absolute bottom-[-30px] left-[343px] w-[268px] rounded-lg shadow-landingPage lg:hidden lg:shadow-landingPageMobile"
      />
    </Container>
  </div>
)

export default MarketplaceSection
