import React from "react"

import { LargeButton } from "./Buttons"
import Container from "./Container"
import NextAvailable from "./NextAvailable"
import TopNav from "./TopNav"
import TrustpilotWidget from "./TrustpilotWidget"

const HeroSection = () => (
  <div className="relative">
    {/* gradients */}
    <div className="absolute top-0 aspect-square w-1/2 rounded-full bg-teal opacity-40 blur-[500px] lg:left-unset lg:right-0 lg:blur-[100px] min_lg:left-[536px]" />
    <div className="absolute left-0 top-[92px] aspect-square w-1/2 rounded-full bg-orange opacity-40 blur-[500px] lg:blur-[100px]" />

    <TopNav />
    <Container className="pt-[112px] lg:px-0 lg:pt-[128px]" animate={false}>
      <div className="flex justify-between lg:flex-col lg:items-center">
        <div className="flex-1">
          <div className="mb-10 lg:mb-4">
            <div className="mb-4 font-serif text-[56px] font-extrabold leading-[72px] lg:px-10 lg:text-center lg:text-[40px] lg:leading-[48px]">
              <span>
                Streamline and <br className="min_lg:hidden" />
                grow
                <br className="lg:hidden" />
                <span className="min_lg:hidden"> </span>
                your <br className="min_lg:hidden" />
              </span>
              <span className="text-teal">holistic practice</span>
            </div>
            <div className="text-2xl leading-9 lg:text-center lg:text-lg lg:leading-7">
              From a professional website to integrated booking & payments, everything you need to attract and manage
              clients—all in one platform.
            </div>
          </div>
          <div className="my-4 lg:hidden">
            <LargeButton href="#book-demo" />
          </div>
          <NextAvailable className="lg:hidden" />
        </div>
        <div className="relative h-[330px] w-[500px] flex-none overflow-hidden rounded-lg lg:mt-6 lg:h-[200px] lg:w-[calc(100%-40px)]">
          <iframe
            src="https://www.loom.com/embed/43c52189479b42f49c4de584823c8b7d?sid=7e6b2827-5e3c-4bac-9b65-036c11ffa3b7&amp;hideEmbedTopBar=true"
            frameBorder="0"
            webkitallowfullscreen=""
            mozallowfullscreen=""
            allowFullScreen=""
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%"
            }}
          />
        </div>
      </div>
    </Container>
    <div className="mx-auto mt-[60px] max-w-[1250px] lg:mt-12 lg:w-[380px]">
      <TrustpilotWidget />
    </div>
  </div>
)

export default HeroSection
