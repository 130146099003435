import React from "react"

import BookDemoSection from "./BookDemoSection"
import BottomCTA from "./BottomCTA"
import CalendarSection from "./CalendarSection"
import CustomWebsiteSection from "./CustomWebsiteSection"
import FAQSection from "./FAQSection"
import HeroSection from "./HeroSection"
import MarketplaceSection from "./MarketplaceSection"
import NewPhotoshootSection from "./NewPhotoshootSection"
import NewPricingSection from "./NewPricingSection"
import OneClickBookingSection from "./OneClickBookingSection"
import VideoTestimonialsSection from "./VideoTestimonialsSection"

const NextAvailableContext = React.createContext()

export const NextAvailableProvider = ({ children, initialNextAvailableTime }) => {
  const [nextAvailableTime, setNextAvailableTime] = React.useState(initialNextAvailableTime)

  return (
    <NextAvailableContext.Provider value={{ nextAvailableTime, setNextAvailableTime }}>
      {children}
    </NextAvailableContext.Provider>
  )
}

export const useNextAvailable = () => React.useContext(NextAvailableContext)

const PractitionerLandingPage = (props) => (
  <NextAvailableProvider initialNextAvailableTime={props.nextAvailableTime}>
    <div>
      <HeroSection />
      <CustomWebsiteSection />
      <OneClickBookingSection />
      <CalendarSection />
      <MarketplaceSection />
      <NewPhotoshootSection />
      <NewPricingSection />
      <BookDemoSection />
      <FAQSection />
      <VideoTestimonialsSection />
      <BottomCTA />
    </div>
  </NextAvailableProvider>
)

export default PractitionerLandingPage
