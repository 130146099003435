import { ArrowRightIcon } from "@heroicons/react/24/outline"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import React, { useEffect, useState } from "react"
import { twMerge } from "tailwind-merge"

import useIsClient from "../../hooks/useIsClient"

import { Button } from "./Buttons"
import { formatNextAvailableDate } from "./NextAvailable"
import { useNextAvailable } from "./PractitionerLandingPage"

dayjs.extend(utc)
dayjs.extend(timezone)

const getVisibleTextInViewport = () => {
  // Helper function to check if an element is in the viewport
  function isElementInViewport(el) {
    const rect = el.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  // Get all text nodes in the document
  function getTextNodesIn(node) {
    let textNodes = []
    if (node.nodeType === Node.TEXT_NODE) {
      textNodes.push(node)
    } else if (
      node.nodeType === Node.ELEMENT_NODE &&
      !["SCRIPT", "STYLE", "NOSCRIPT", "IFRAME", "OBJECT"].includes(node.tagName)
    ) {
      for (let child of node.childNodes) {
        textNodes = textNodes.concat(getTextNodesIn(child))
      }
    }
    return textNodes
  }

  // Get all visible text nodes in the viewport
  const allTextNodes = getTextNodesIn(document.body)
  const visibleText = allTextNodes
    .filter((node) => isElementInViewport(node.parentElement)) // Filter by visibility in viewport
    .map((node) => node.textContent.trim()) // Get the text content
    .filter((text) => text.length > 0) // Remove empty strings

  return visibleText.join(" ")
}

const stripNextAvailable = (str) => {
  const prefix = "Next available:"
  if (str.startsWith(prefix)) {
    return str.slice(prefix.length).trim()
  }
  return str
}

const NextAvailable = ({ isVisible }) => {
  const isClient = useIsClient()
  const { nextAvailableTime } = useNextAvailable()

  if (!isClient) return null
  if (!nextAvailableTime?.startTime) return null

  const readableTime = formatNextAvailableDate(nextAvailableTime.startTime)

  return (
    <div
      className={twMerge(
        "inline-flex h-10 items-center justify-start gap-4 rounded bg-white/80 px-3 py-2 shadow backdrop-blur-sm transition-all duration-300 lg:hidden",
        isVisible ? "translate-x-0 opacity-100" : "-translate-x-4 opacity-0"
      )}>
      <div className="text-center">
        <span>Next available: </span>
        <span className="font-bold">{readableTime}</span>
      </div>
      <ArrowRightIcon className="h-6 w-6" />
    </div>
  )
}

const TopNav = () => {
  const [visibleText, setVisibleText] = useState("")
  const [showNextAvailable, setShowNextAvailable] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setVisibleText(stripNextAvailable(getVisibleTextInViewport()))
    }

    window.addEventListener("scroll", handleScroll)
    handleScroll() // Initial call

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  useEffect(() => {
    setShowNextAvailable(!visibleText.includes("Next available"))
  }, [visibleText])

  return (
    <div className="fixed inset-x-0 top-0 z-50 flex items-center justify-center bg-gradient-to-b from-white/80 to-white/0 p-8 backdrop-blur-[2px] lg:px-5 lg:py-6">
      <div className="flex w-full max-w-[1376px] items-center justify-between">
        <a href="/">
          <img src="/images/healme_logo.svg" alt="Logo" className="h-10 lg:h-8" />
        </a>
        <div className="flex items-center gap-8">
          <NextAvailable isVisible={showNextAvailable} />
          <Button href="#book-demo">Join as a practitioner</Button>
        </div>
      </div>
    </div>
  )
}

export default TopNav
