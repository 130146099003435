import React from "react"

import Container from "./Container"
import CopySection from "./CopySection"

const OneClickBookingSection = () => (
  <div className="relative lg:py-8">
    {/* gradient */}
    <div className="absolute left-[358px] top-[-40px] aspect-square w-1/2 rounded-full bg-gradient-to-b from-[#f9e4cc] to-[#ff6100] opacity-40 blur-[600px] lg:left-0 lg:blur-[100px]" />

    <Container className="flex h-[631px] flex-row-reverse items-center justify-between lg:h-auto lg:flex-col-reverse lg:items-center lg:gap-8">
      <div className="absolute left-[-102px] top-[13px] flex flex-col items-start gap-12 lg:static lg:flex-row lg:flex-wrap lg:items-center lg:justify-center lg:gap-8">
        <img
          loading="lazy"
          src="/images/practitioner-landing/logos/square.svg"
          alt="Square logo"
          className="max-w-[94px] opacity-50 shadow-landingPage lg:shadow-landingPageMobile"
        />
        <img
          loading="lazy"
          src="/images/practitioner-landing/logos/stripe.svg"
          alt="Stripe logo"
          className="max-w-[94px] opacity-50 shadow-landingPage lg:shadow-landingPageMobile"
        />
        <img
          loading="lazy"
          src="/images/practitioner-landing/logos/venmo.svg"
          alt="Venmo logo"
          className="max-w-[94px] opacity-50 shadow-landingPage lg:shadow-landingPageMobile"
        />
        <img
          loading="lazy"
          src="/images/practitioner-landing/logos/paypal.svg"
          alt="PayPal logo"
          className="max-w-[94px] opacity-50 shadow-landingPage lg:shadow-landingPageMobile"
        />
        <img
          loading="lazy"
          src="/images/practitioner-landing/logos/cash.svg"
          alt="Cash App logo"
          className="max-w-[94px] opacity-50 shadow-landingPage lg:shadow-landingPageMobile"
        />
        <img
          loading="lazy"
          src="/images/practitioner-landing/logos/zelle.svg"
          alt="Zelle logo"
          className="max-w-[94px] opacity-50 shadow-landingPage lg:hidden lg:shadow-landingPageMobile"
        />
      </div>
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/booking-large.png"
        alt="Booking form"
        className="absolute left-[45px] top-0 w-[375px] shadow-landingPage lg:static lg:mx-auto lg:mt-10 lg:w-[240px] lg:shadow-landingPageMobile"
      />
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/notification/booked-confirmation.png"
        alt="Booking confirmation"
        className="absolute left-[138px] top-[251px] w-[375px] rounded-lg shadow-landingPage lg:static lg:mx-auto lg:mt-[-250px] lg:hidden lg:w-[320px] lg:shadow-landingPageMobile"
      />
      <CopySection
        title="Turn one-time clients into regulars with integrated 1-click booking."
        className="max-w-[527px]">
        <ul className="space-y-2">
          <li className="flex items-center gap-2">
            <span>👉</span>
            <span>
              <span className="font-bold">1-click booking</span> boosts repeat business 30-50%
            </span>
          </li>
          <li className="flex items-center gap-2">
            <span>💸</span>
            <span>
              <span className="font-bold">Accept payments</span> via credit card, Venmo, or PayPal
            </span>
          </li>
          <li className="flex items-center gap-2">
            <span>🔔</span>
            <span>
              <span className="font-bold">Automated appointment reminders</span>
            </span>
          </li>
          <li className="flex items-center gap-2">
            <span>🔃</span>
            <span>
              <span className="font-bold">Recurring bookings</span> for loyal clients
            </span>
          </li>
          <li className="flex items-center gap-2">
            <span>📱</span>
            <span>
              <span className="font-bold">Client mobile app</span> for instant booking
            </span>
          </li>
        </ul>
      </CopySection>
    </Container>
  </div>
)

export default OneClickBookingSection
