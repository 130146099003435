import React, { useState, useEffect } from "react"

import Container from "./Container"
import CopySection from "./CopySection"

const CustomWebsiteSection = () => {
  const [currentImage, setCurrentImage] = useState(0)
  const images = [
    "/images/practitioner-landing/graphics/lp-elements/laptop-screen-1.png",
    "/images/practitioner-landing/graphics/lp-elements/laptop-screen-2.png",
    "/images/practitioner-landing/graphics/lp-elements/laptop-screen-3.png",
    "/images/practitioner-landing/graphics/lp-elements/laptop-screen-4.png",
    "/images/practitioner-landing/graphics/lp-elements/laptop-screen-5.png",
    "/images/practitioner-landing/graphics/lp-elements/laptop-screen-6.png"
  ]

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length)
    }, 4000)

    return () => clearInterval(timer)
  }, [])

  return (
    <div className="pb-20 pt-10 lg:py-8">
      <Container>
        <CopySection title="Upgrade to a beautiful, modern website that attracts & converts new clients.">
          <ul className="space-y-2">
            <li className="flex items-center gap-2">
              <span>🌿</span>
              <span>
                <span className="font-bold">Professionally designed</span> for holistic practices
              </span>
            </li>
            <li className="flex items-center gap-2">
              <span>✨</span>
              <span>
                <span className="font-bold">Personalized and tailored</span> to your ideal client
              </span>
            </li>
            <li className="flex items-center gap-2">
              <span>🔎</span>
              <span>
                <span className="font-bold">SEO-optimized</span> for more organic reach
              </span>
            </li>
            <li className="flex items-center gap-2">
              <span>💬</span>
              <span>
                <span className="font-bold">24/7 Lead capture</span> to engage new clients
              </span>
            </li>
            <li className="flex items-center gap-2">
              <span>☁️</span>
              <span>
                <span className="font-bold">Website hosting</span> included & fully managed
              </span>
            </li>
          </ul>
        </CopySection>
        <img
          src="/images/practitioner-landing/graphics/lp-elements/laptop.png"
          alt="Laptop"
          className="absolute left-[641px] top-0 h-[476px] overflow-hidden rounded-lg lg:static lg:mx-auto lg:mt-10 lg:h-auto lg:w-full"
        />
        {images.map((img, index) => (
          <img
            key={img}
            src={img}
            alt={`Website example ${index + 1}`}
            loading="lazy"
            className={`absolute left-[731px] top-[22px] h-[362px] w-[578px] transition-opacity duration-1000 lg:left-1/2 lg:right-auto lg:top-[320px] lg:hidden lg:aspect-[578/362] lg:w-[calc(100%-100px)] lg:-translate-x-1/2 ${
              currentImage === index ? "opacity-100" : "opacity-0"
            }`}
          />
        ))}
      </Container>
    </div>
  )
}

export default CustomWebsiteSection
