import { ArrowUpIcon } from "@heroicons/react/24/outline"
import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone"
import utc from "dayjs/plugin/utc"
import React from "react"
import { twMerge } from "tailwind-merge"

import useIsClient from "../../hooks/useIsClient"

import { useNextAvailable } from "./PractitionerLandingPage"

dayjs.extend(utc)
dayjs.extend(timezone)

export const formatNextAvailableDate = (isoString) => {
  const userTimezone = dayjs.tz.guess()
  return dayjs(isoString).tz(userTimezone).format("dddd [at] h:mm A z")
}

const NextAvailable = ({ className }) => {
  const isClient = useIsClient()
  const { nextAvailableTime } = useNextAvailable()

  if (!isClient) return null
  if (!nextAvailableTime?.startTime) return null

  const readableTime = formatNextAvailableDate(nextAvailableTime.startTime)

  return (
    <div className={twMerge("inline-flex items-center justify-start gap-2 text-gray-dark", className)}>
      <ArrowUpIcon className="h-6 w-6" />
      <div className="text-center">
        <span>Next available: </span>
        <span className="font-bold">{readableTime}</span>
      </div>
    </div>
  )
}

export default NextAvailable
