import React from "react"

import Container from "./Container"
import CopySection from "./CopySection"

const CalendarSection = () => (
  <div className="lg:py-8">
    <Container className="flex h-[735px] items-center justify-between px-8 lg:h-auto lg:max-w-[380px] lg:flex-col lg:items-center lg:gap-8">
      <CopySection className="max-w-[480px]" title="Manage your calendar, clients, payments, and more.">
        <ul className="space-y-2">
          <li className="flex items-center gap-2">
            <span>🗓️</span>
            <span>
              <span className="font-bold">Calendar & CRM</span> for easy scheduling
            </span>
          </li>
          <li className="flex items-center gap-2">
            <span>📱</span>
            <span>
              <span className="font-bold">Mobile app</span> for on-the-go management
            </span>
          </li>
          <li className="flex items-center gap-2">
            <span>📆</span>
            <span>
              <span className="font-bold">Calendar sync</span> with Google, Outlook, & iCloud
            </span>
          </li>
          <li className="flex items-center gap-2">
            <span>📝</span>
            <span>
              <span className="font-bold">Client notes & Intake forms</span> for documentation
            </span>
          </li>
        </ul>
      </CopySection>
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/calendar-large.png"
        alt="Desktop website"
        className="absolute left-[548px] top-0 w-[374px] shadow-landingPage lg:static lg:mx-auto lg:mt-10 lg:w-[320px] lg:shadow-landingPageMobile"
      />
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/payments-large.png"
        alt="Desktop website"
        className="absolute left-[687px] top-[393px] w-[374px] shadow-landingPage lg:static lg:mx-auto lg:hidden lg:w-[320px] lg:shadow-landingPageMobile"
      />
      <img
        loading="lazy"
        src="/images/practitioner-landing/graphics/lp-elements/hipaa.png"
        alt="HIPAA compliance"
        className="absolute left-[948px] top-[210px] w-[160px] lg:static lg:mx-auto lg:hidden lg:w-[320px]"
      />
      <div className="flex flex-col items-start gap-16 lg:flex-row lg:flex-wrap lg:items-center lg:justify-center lg:gap-8">
        <img
          loading="lazy"
          src="/images/practitioner-landing/logos/icon/outlook.svg"
          alt="Outlook logo"
          className="h-16 bg-transparent opacity-50 shadow-landingPage lg:w-10 lg:shadow-landingPageMobile"
        />
        <img
          loading="lazy"
          src="/images/practitioner-landing/logos/icon/ical.svg"
          alt="iCal logo"
          className="h-16 bg-transparent opacity-50 shadow-landingPage lg:w-10 lg:shadow-landingPageMobile"
        />
        <img
          loading="lazy"
          src="/images/practitioner-landing/logos/icon/google-calendar.svg"
          alt="Google calendar logo"
          className="h-16 bg-transparent opacity-50 shadow-landingPage lg:w-10 lg:shadow-landingPageMobile"
        />
        <img
          loading="lazy"
          src="/images/practitioner-landing/logos/icon/exchange.svg"
          alt="Exchange logo"
          className="h-16 bg-transparent opacity-50 shadow-landingPage lg:w-10 lg:shadow-landingPageMobile"
        />
        <img
          loading="lazy"
          src="/images/practitioner-landing/logos/icon/365.svg"
          alt="Office 365 logo"
          className="h-16 bg-transparent opacity-50 shadow-landingPage lg:w-10 lg:shadow-landingPageMobile"
        />
      </div>
    </Container>
  </div>
)

export default CalendarSection
